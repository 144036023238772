import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingService } from './services/shared/loading.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { NavigationError, Router } from '@angular/router';
import { AuthService } from './services/shared/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogComponent } from './components/dialog/dialog.component';
import { CheckForUpdateService } from './services/shared/check.update.service';
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {
  title = 'cix-employer-web';

  public isLoading: boolean = false;
  constructor(
    private loadingService: LoadingService,
    private bnIdle: BnNgIdleService,
    private router: Router,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    private sw: CheckForUpdateService
  ) {
    // this.sw.checkForUpdates();
  }
  ngOnInit() {
    // Updates loading boolean
    this.loadingService.loading$.pipe(untilDestroyed(this)).subscribe((isLoading: boolean) => {
      setTimeout(() => {
        this.isLoading = isLoading
      }, 1);
    });
    this.setIdleCount();
    this.router.events
      .pipe(
        filter(evt => evt instanceof NavigationError),
        map(evt => evt as NavigationError)
      )
      .subscribe(evt => {
        if (evt.error instanceof Error && evt.error.name == 'ChunkLoadError') {
          window.location.assign(evt.url);
        }
      });
    this.swUpdate.versionUpdates
      .subscribe(evt => {
        if (evt.type === 'VERSION_READY') {
          this.dialog.open(AppDialogComponent, {
            width: '400px',
            autoFocus: false,
            data: {
              primaryText: `A new version is available, would you like to refresh?`,
              submitText: 'Refresh',
              titleText: 'New App Version'
            }
          }).afterClosed().subscribe((closedResult: any) => {
            if (closedResult) {
              document.location.reload();
            }
          });
        }
      });
  }

  setIdleCount() {
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if (this.router.url === '/landing' || this.router.url === '/login') {
          return;
        } else {
          this.router.navigate(['/login']);
          this.authService.signOut();
        }
      }
    });
  }
}